import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API } from "./api-constants/index";

@Injectable({
  providedIn: "root",
})
export class PreferenceService {
  authorization = { headers: new HttpHeaders({ authorization: "True" }) };
  constructor(private http: HttpClient) {}

  public setLatLng(data): Observable<any> {
    return this.http.post(`${API.SET_LAT_LONG}`, data, this.authorization);
  }

  public getLatLng(): Observable<any> {
    return this.http.get(`${API.SET_LAT_LONG}`, this.authorization);
  }
}
