import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { API } from "./api-constants/index";

@Injectable({
  providedIn: "root",
})
export class AuctionService {
  authorization = { headers: new HttpHeaders({ authorization: "True" }) };
  constructor(private http: HttpClient) {}

  public getEarthTile(): Observable<any> {
    return this.http.get(`${API.LIST_OF_EARTHTILES}`, this.authorization);
  }

  public createAuction(auctionData): Observable<any> {
    return this.http.post(
      `${API.CREATE_AUCTION}`,
      auctionData,
      this.authorization
    );
  }
  public createInvestorAuction(auctionData): Observable<any> {
    return this.http.post(
      `${API.CREATE_INVESTOR_AUCTION}`,
      auctionData,
      this.authorization
    );
  }
}
